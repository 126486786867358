const errorStatuses = [
  "failed",
  "error",
  "invalid_plan",
  "invalid_zip",
  "invalid_iccid",
  "timeout",
  "sim_inactive",
  "suspended",
  "invalid_areacode",
  "cancelled",
  "canceled",
  "invalid_msisdn",
  "invalid_status",
  "cooldown",
];
const pendingStatuses = ["pending", "in_progress", "submitted"];

const telitChangePlanPendingStatus = [
  ...pendingStatuses,
  "deactivating",
  "deactivated",
  "activating",
  "activated",
];

const getChipColor = (status, telitChangePlan = false) => {
  if (telitChangePlan && telitChangePlanPendingStatus.includes(status)) {
    return "warning";
  }

  switch (status) {
    case "in_progress":
    case "pending":
      return "warning";
    case "invalid_iccid":
    case "error":
    case "failed":
    case "invalid_plan":
    case "invalid_zip":
    case "invalid_areacode":
    case "timeout":
    case "suspended":
    case "cancelled":
    case "canceled":
    case "invalid_msisdn":
    case "invalid_status":
    case "cooldown":
      return "error";
    case "already_active":
    case "sim_inactive":
    case "already_deactivated":
    case "already_paused":
    case "already_refilled":
    case "already_active_bad_msisdn":
    case "already_ported":
      return "info";
    default:
      return "success";
  }
};

const getStatusCount = (statusData, telitChangePlan = false) => {
  const count = { success: 0, error: 0, pending: 0 };

  Object.keys(statusData).map((status) => {
    if (errorStatuses.includes(status)) {
      count.error = count.error + statusData[status];
    } else if (
      (telitChangePlan && telitChangePlanPendingStatus.includes(status)) ||
      pendingStatuses.includes(status)
    ) {
      count.pending = count.pending + statusData[status];
    } else {
      count.success = count.success + statusData[status];
    }
  });

  return count;
};

const CLIENTS = {
  ultra: "ultra",
  telit: "telit",
  sparq: "sparq",
  cricket: "cricket",
  verizon: "verizon",
  tmobile: "tmobile",
  selectel: "selectel",
};

const OPERATIONS = {
  activate: "activate",
  change_number: "change_number",
  change_plan: "change_plan",
  deactivate: "deactivate",
  pause: "pause",
  restore: "restore",
  sim_info: "sim_info",
  unpause: "unpause",
  refill: "refill",
  portin: "portin",
  reset: "reset",
  hotline: "hotline",
  wifi_calling: "wifi_calling",
  imsi_create: "imsi_create",
  imsi_delete: "imsi_delete",
};

const NOT_RETRY_STATUSES = ["active", "already_active", "completed", "changed"];
class CustomError extends Error {
  constructor(message) {
    super(message);
    this.name = "CustomError";
  }
}

const getValidRetryLines = (batchData) =>
  batchData.reduce((acc, item) => {
    if (
      !NOT_RETRY_STATUSES.includes(item.status) &&
      (item.extra_data.user_zip || item.extra_data.user_areacode)
    ) {
      acc.push(item);
    }
    return acc;
  }, []);

export {
  getChipColor,
  CLIENTS,
  getStatusCount,
  OPERATIONS,
  NOT_RETRY_STATUSES,
  CustomError,
  getValidRetryLines,
};
