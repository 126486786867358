import debounce from "lodash.debounce";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import Stack from "@mui/material/Stack";

import Search from "../../Search/Search";
import Title from "../../../common/Title";
import BatchList from "../../../common/BatchList";

import useAxios from "../../../../hooks/useAxios";
import { __getIMSIBatches } from "../../../../api/sim";

const IMSIBatchList = ({ isClient, client, operation }) => {
  const [data, setData] = useState([]);

  const nav = useNavigate();

  const [searchActive, setSearchActive] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [metaData, setMetaData] = useState({
    page: 1,
    page_size: 100,
    total: 1,
  });
  const [response, call, loading] = useAxios({ ...__getIMSIBatches });

  const debouncedFetchData = debounce((query) => {
    call(query);
  }, 500);

  useEffect(() => {
    setMetaData({
      page: 1,
      page_size: 100,
      total: 1,
    });
    setData([]);
  }, []);

  useEffect(() => {
    const params = {
      page: currentPage,
      page_size: pageSize,
      operation: operation,
    };

    if (!isClient) {
      params.client = client;
    }

    debouncedFetchData({
      params,
    });

    return () => {
      debouncedFetchData.cancel();
    };
  }, [currentPage, pageSize]);

  useEffect(() => {
    if (response && response.status) {
      setMetaData({
        page: response.data.page,
        page_size: response.data.page_size,
        total: response.data.total,
      });
      setData(response.data.data);
    }
  }, [response]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (newPageSize) => {
    setCurrentPage(1);
    setPageSize(newPageSize);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Title
        variant="h5"
        sx={{ textTransform: "capitalize" }}
        content={`IMSI`}
        gutterBottom
      />
      <Search
        key={`${client}-imsi`}
        client={"ultra"}
        operation={operation}
        searchActive={searchActive}
        setSearchActive={setSearchActive}
      />
      {/* if search not active show as desired */}
      {!searchActive &&
        (response ? (
          <>
            <Grid container justifyContent="flex-start" spacing={2}>
              <Grid item>
                <Stack spacing={2} direction="row">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => nav("form")}
                  >
                    {`Submit new sims`}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
            <BatchList
              data={data}
              loading={loading}
              meta={metaData}
              changeNumber={true}
              handlePageChange={handlePageChange}
              handlePageSizeChange={handlePageSizeChange}
            />
          </>
        ) : (
          <Title content="Please wait..." variant="subtitle2" gutterBottom />
        ))}
    </Box>
  );
};

export default IMSIBatchList;
