export const allItemTop = [
  {
    text: "Ultra",
    to: "/ultra",
    subMenu: [
      { text: "Activate", to: "activate" },
      { text: "Deactivate", to: "deactivate" },
      { text: "Change Number", to: "change_number" },
      { text: "Change Plan", to: "change_plan" },
      { text: "Pause", to: "pause" },
      { text: "Unpause", to: "unpause" },
      { text: "Restore", to: "restore" },
      { text: "Portin", to: "portin" },
      { text: "Sim Info", to: "sim_info" },
      { text: "Reset", to: "reset" },
      { text: "Esim", to: "esim" },
      { text: "Wifi Calling", to: "wifi_calling" },
      { text: "Credential", to: "credential" },
    ],
  },
  {
    text: "Telit",
    to: "/telit",
    subMenu: [
      { text: "Activate", to: "activate" },
      { text: "Deactivate", to: "deactivate" },
      { text: "Change Number", to: "change_number" },
      { text: "Change Plan", to: "change_plan" },
      { text: "Pause", to: "pause" },
      { text: "Sim Info", to: "sim_info" },
      { text: "Reset", to: "reset" },
      { text: "Credential", to: "credential" },
    ],
  },
  {
    text: "Sparq",
    to: "/sparq",
    subMenu: [
      { text: "Activate", to: "activate" },
      { text: "Deactivate", to: "deactivate" },
      { text: "IMSI Create", to: "imsi/imsi_create" },
      { text: "IMSI Delete", to: "imsi/imsi_delete" },
      { text: "Credential", to: "credential" },
    ],
  },
  {
    text: "Cricket",
    to: "/cricket",
    subMenu: [
      { text: "Activate", to: "activate" },
      { text: "Refill", to: "refill" },
      { text: "Credential", to: "credential" },
    ],
  },
  {
    text: "Verizon",
    to: "/verizon",
    subMenu: [
      { text: "Activate", to: "activate" },
      { text: "Refill", to: "refill" },
      { text: "Credential", to: "credential" },
    ],
  },
  {
    text: "T-Mobile",
    to: "/tmobile",
    subMenu: [
      { text: "Activate", to: "activate" },
      { text: "Credential", to: "credential" },
    ],
  },
  {
    text: "Selectel",
    to: "/selectel",
    subMenu: [
      { text: "Change Number", to: "change_number" },
      { text: "Hotline", to: "hotline" },
      { text: "Sim Info", to: "sim_info" },
      { text: "Credential", to: "credential" },
    ],
  },
  {
    text: "User",
    to: "/user",
  },
];

export const userItems = [
  {
    text: "Activate",
    to: "/client/activate",
    hasNoLink: true,
  },
  {
    text: "Change Number",
    to: "/client/change_number",
    hasNoLink: true,
  },
  {
    text: "Deactivate",
    to: "/client/deactivate",
    hasNoLink: true,
  },
  {
    text: "Sim Info",
    to: "/client/sim_info",
    hasNoLink: true,
  },
  {
    text: "Reset",
    to: "/client/reset",
  },
];
